import {
  Box,
  SkeletonText,
  Text,
  SimpleGrid,
  Badge,
  Button,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import React, { useEffect, useState } from "react";
import userApi from "../api/userApi";
import "./FundPick.css";
import firebase from "firebase";
import { Avatar, AvatarBadge, Wrap, WrapItem } from "@chakra-ui/react";
export default function FundPick({ onClick, onLoaded }) {
  let p = [];
  const [profile, setProfile] = useState([]);
  const getUsers = async (slug) => {
    await userApi.getFundUsers(slug).then((a) => {
      p.push(a.data);
    });
  };
  let fundMap = () =>
    funds.map((a) => {
      getUsers(a.slug);
      let balance = (a.fundsAvailable / 100).toFixed(2);

      return (
        <div>
          <div>
            <li key={a.slug}>
            <Box
              borderRadius="lg"
              overflow="hidden"
              bgGradient="linear(to-l, #7928CA,#FF0080)"
              // backgroundColor="white"
              boxShadow="lg"
              width="350px"
              height="200px"
              textAlign="left"
              onClick={() => onClick(a.slug)}
              style={{ cursor: "pointer" }}
              marginTop="20px"
            >
              <Box p="6">
                <Box d="flex" alignItems="baseline">
                  <Badge
                    borderRadius="full"
                    px="2"
                    fontSize="15px"
                    backgroundColor={
                      a.contractStatus == "approve" ? "#d1ffd3" : "#ffead1"
                    }
                  >
                    {a.contractStatus == "approve" ? "Approved" : "Pending"}
                  </Badge>
                </Box>
                <Text
                  bgClip="text"
                  fontSize="2xl"
                  color="white"
                  fontWeight="800"
                  marginTop="10px"
                >
                  {a.name}
                </Text>
                <Text
                  bgClip="text"
                  fontSize="1xl"
                  color="white"
                  fontWeight="500"
                >
                  {a.slug}
                </Text>
                <Text
                  bgClip="text"
                  fontSize="2xl"
                  color="white"
                  fontWeight="800"
                >
                  ${balance}
                </Text>
              </Box>
            </Box>
            </li>
          </div>
        </div>
      );
    });

  let [funds, setFunds] = useState(false);
  useEffect(() => {
    requestFunds();
  }, []);
  let requestFunds = async () => {
    onLoaded(false);
    await userApi.getUserFunds().then((c) => {
      setFunds(c.data);
      onLoaded(true);
      console.log(c);
    });
  };

  return (
    <div className="main" style={{ width: "100%", height: "100%" }}>
      <Text
        bgGradient="linear(to-l, #7928CA,#FF0080)"
        bgClip="text"
        fontSize="6xl"
        fontWeight="extrabold"
      >
        Choose your Fund
      </Text>

      <div className="fundBox">
        {funds && (
          <SimpleGrid gap={10} columns={3} style={{}}>
            {fundMap()}
          </SimpleGrid>
        )}

        {!funds && (
          <>
            <Box
              padding="6"
              boxShadow="lg"
              bg="white"
              width="350px"
              height="200px"
              margin="20px"
            >
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
            </Box>
            <Box
              padding="6"
              boxShadow="lg"
              bg="white"
              width="350px"
              height="200px"
              margin="20px"
            >
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
            </Box>
            <Box
              padding="6"
              boxShadow="lg"
              bg="white"
              width="350px"
              height="200px"
              margin="20px"
            >
              <SkeletonText mt="4" noOfLines={4} spacing="4" />
            </Box>
          </>
        )}
      </div>
    </div>
  );
}
