import firebase from "firebase";
import "firebase/functions";
const version = "a_001_";

const getUserFunds = async (data) => {
  let readFund = firebase
    .functions()
    .httpsCallable(version + "fund-readUserFunds");
  return readFund({
    data: {},
  })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
};

const fundReadJWT = async (slug) => {
  let readFund = firebase.functions().httpsCallable(version + "fund-readJWT");
  console.log("slug is ", slug);
  return readFund({
    slug: slug,
  })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getFundUsers = async (data) => {
  let readFundUsers = firebase
    .functions()
    .httpsCallable(version + "fund-readFundUsers");
  return readFundUsers({
    slug: data,
  })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
};

export default {
  getUserFunds,
  fundReadJWT,
  getFundUsers,
};
