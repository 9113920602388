import { ScaleFade } from "@chakra-ui/react";
import firebase from "firebase";
import { useState } from "react";
import { GoogleLogin } from "react-google-login";
import firebaseConfig from "../api/firebaseConfig";
import userApi from "../api/userApi";
import {
  Button,
  ButtonGroup,
  Text,
  Progress,
  CircularProgress,
  Spinner,
} from "@chakra-ui/react";

import { ArrowForwardIcon } from "@chakra-ui/icons";

import FundPick from "./FundPick";
function Auth() {
  const [loaded, setLoaded] = useState(false);
  let url = "https://campaigns.fundsy.io/auth?token=";
  // console.log("fireabase", firebase.auth().currentUser);
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const [isOpen, setOpen] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [done, setDone] = useState(false);

  // let history = useHistory();
  firebase.auth().onAuthStateChanged((a) => {
    setLoaded(a);
    setOpen(a);
  });

  const isUserEqual = (googleUser, firebaseUser) => {
    if (firebaseUser) {
      var providerData = firebaseUser.providerData;
      for (var i = 0; i < providerData.length; i++) {
        if (
          providerData[i].providerId ===
            firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
          providerData[i].uid === googleUser.getBasicProfile().getId()
        ) {
          // We don't need to reauth the Firebase connection.
          return true;
        }
      }
    }
    return false;
  };

  const onSignIn = (googleUser) => {
    // console.log("Google Auth Response", googleUser);
    // We need to register an Observer on Firebase Auth to make sure auth is initialized.
    var unsubscribe = firebase
      .auth()
      .onAuthStateChanged(function (firebaseUser) {
        console.log("f user", firebaseUser);
        console.log("g user ", googleUser);
        unsubscribe();
        // Check if we are already signed-in Firebase with the correct user.
        if (!isUserEqual(googleUser, firebaseUser)) {

          if (googleUser.tokenObj) {
            var credential = firebase.auth.GoogleAuthProvider.credential(
              googleUser.tokenId,
              googleUser.accessToken
            );
            // Sign in with credential from the Google user.
            firebase
              .auth()
              .signInWithCredential(credential)
              .then(() => {
                setLoaded(true);
                // console.log(firebase.auth().currentUser);
              })
              .catch(function (error) {
                // Handle Errors here.
                // var errorCode = error.code;
                // var errorMessage = error.message;
                // // The email of the user's account used.
                // var email = error.email;
                // // The firebase.auth.AuthCredential type that was used.
                // var credential = error.credential;
                // ...
              });
          }
          // Build Firebase credential with the Google ID token.
          
        } else {
          //user is already in firebase so don't need to do onboarding
          console.log(2, firebase.auth().currentUser);
          console.log("User already signed-in Firebase.");
        }
      });
  };

  const redirect = async (slug) => {
    setPressed(true);
    await userApi
      .fundReadJWT(slug)
      .then((res) => {
        window.location.assign(url + res);
      })
      .catch((a) => console.log(a));
  };

  return (
    <div
      className="App test"
      style={{
        width: "100%",
        height: "100%",
        // minheight: "100vh",
      }}
    >
      {!loaded && (
        <div
          style={{
            width: "100vw",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            bgGradient="linear(to-l, #7928CA,#FF0080)"
            bgClip="text"
            fontSize="4xl"
            marginBottom="10px"
            fontWeight="extrabold"
          >
            Fundsy Campaigns
          </Text>
          <Text bgClip="text" fontSize="1xl" marginBottom="20px" color="black">
            You'll need to have signed up for Fundsy through the app.
          </Text>
          <GoogleLogin
            clientId="374175946329-0g56bq4umsmmgcj93utaqhqrs1il3hdc.apps.googleusercontent.com"
            buttonText="Login with Fundsy"
            onSuccess={onSignIn}
            onFailure={onSignIn}
            cookiePolicy={"single_host_origin"}
            scopes={["profile", "email"]}
            render={(renderProps) => (
              <Button
                rightIcon={<ArrowForwardIcon />}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                backgroundColor="#6C6CE5"
                width="220px"
                height="60px"
                borderRadius="15px"
              >
                Sign in with Google
              </Button>
            )}
          />
        </div>
      )}
      {loaded && (
        <ScaleFade initialScale={0.9} in={isOpen}>
          {!pressed && (
            <>
              <FundPick
                onClick={(slug) => {
                  redirect(slug);
                }}
                onLoaded={(res) => {
                  setDone(res);
                }}
              />

              {done && (
                <Button
                  rightIcon={<ArrowForwardIcon />}
                  onClick={() => firebase.auth().signOut()}
                  backgroundColor="#6C6CE5"
                  width="220px"
                  height="60px"
                  borderRadius="15px"
                >
                  Log out
                </Button>
              )}
            </>
          )}
          {pressed && (
            <>
              <Text
                bgClip="text"
                fontSize="2xl"
                marginBottom="20px"
                fontWeight="800"
                color="#6C6CE5"
              >
                Teleporting you to your Fund...
              </Text>
              <CircularProgress isIndeterminate color="#6C6CE5" />
            </>
          )}
        </ScaleFade>
      )}
    </div>
  );
}

export default Auth;
