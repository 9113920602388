import { ChakraProvider } from "@chakra-ui/react";
import firebase from "firebase";
import { Route, Switch } from "react-router-dom";
import firebaseConfig from "./api/firebaseConfig";
import "./App.css";
import Auth from "./pages/Auth";

function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  return (
    <ChakraProvider>
      <Switch>
        <Route path="/" exact component={Auth} />
      </Switch>
    </ChakraProvider>
  );
}

export default App;
