// let firebaseConfig = {
//   apiKey: "AIzaSyCAZF1jlaT3OwPVnwfCtk8PBEv5yrrAymE",
//   authDomain: "fundsy-sandbox.firebaseapp.com",
//   databaseURL: "https://fundsy-sandbox.firebaseio.com",
//   projectId: "fundsy-sandbox",
//   storageBucket: "fundsy-sandbox.appspot.com",
//   messagingSenderId: "775585794936",
//   appId: "1:775585794936:web:18382cf1db6232d7abd68d",
//   measurementId: "G-RFJK18TY79",
// };

const firebaseConfig = {
	apiKey: "AIzaSyAQ3HDRfNqDfmjr6jzPH5-EyhvoeRU5yko",
	authDomain: "fundsy-private-beta.firebaseapp.com",
	projectId: "fundsy-private-beta",
	storageBucket: "fundsy-private-beta.appspot.com",
	messagingSenderId: "374175946329",
	appId: "1:374175946329:web:788b7cbb340f9cfdcf0348",
	measurementId: "G-ZH356YHL21"
  };

export default firebaseConfig;
